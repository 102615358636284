import queryString from 'query-string'

/** The following functions and types (`_PathParam`, `PathParam`, `invariant`,
 * and `generatePath`) are modified from react-router's `generatePath` implementation
 *  which allows us to use string interpolation to build our paths with route
 * parameters, and then compare our paths in the Breadcrumbs component to render
 * custom breadcrumbs. */

// Recursive helper for finding path parameters in the absence of wildcards
// eslint-disable-next-line @typescript-eslint/naming-convention
type _PathParam<Path extends string> =
  // split path into individual path segments
  Path extends `${infer L}/${infer R}`
    ? _PathParam<L> | _PathParam<R>
    : // find params after `:`
    Path extends `${string}:${infer Param}`
    ? Param
    : // otherwise, there aren't any params present
      never

type PathParam<Path extends string> =
  // check if path is just a wildcard
  Path extends '*'
    ? '*'
    : // look for wildcard at the end of the path
    Path extends `${infer Rest}/*`
    ? '*' | _PathParam<Rest>
    : // look for params in the absence of wildcards
      _PathParam<Path>

export function invariant(value: boolean, message?: string): asserts value
export function invariant<T>(
  value: T | null | undefined,
  message?: string
): asserts value is T
export function invariant(value: any, message?: string) {
  if (value === false || value === null || typeof value === 'undefined') {
    throw new Error(message)
  }
}

export function generatePath<Path extends string>(
  path: Path,
  params: {
    [key in PathParam<Path>]: string
  } = {} as any
): string {
  return path.replace(/\[(\w+)\]/g, (_, key: PathParam<Path>) => {
    invariant(params[key] != null, `Missing "[${key}]" param`)
    return params[key]!
  })
}

/**
 * A utility function for working with next/router paths. It allows us to both define
 * routes and build valid links to those routes with the same function, making it easier
 * to link to, search for, and refactor our routes (no more magic strings!)
 *
 * When called with only a `path`, it will return that path. This is useful when defining
 * a route, or creating a link to a simple, non-parameterised route.
 *
 * When called with `routeParams`, it will return a link to the path, substituting the
 * route param values for the placeholders in the path.
 *
 * When called with `queryParams`, it will return a link to the path, including a
 * queryString containing the query params.
 *
 */

interface BuildPathArgs {
  path: string
  routeParams?:
    | {
        [x: string]: string | undefined
      }
    | undefined
  queryParams?: Record<string, any>
}

export type PathArgs = Omit<BuildPathArgs, 'path'>

export const buildPath = ({
  path,
  routeParams,
  queryParams
}: BuildPathArgs): string => {
  const pathname = routeParams ? generatePath(path, routeParams) : path
  const search = queryParams ? `?${queryString.stringify(queryParams)}` : ''

  return `${pathname}${search}`
}
