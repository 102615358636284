import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

import { Image } from 'src/components/atoms/Image'
import { ExternalLink } from 'src/components/atoms/Link'
import { Heading1, Paragraph } from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'

export const ServiceUnavailable = () => {
  const router = useRouter()

  useEffect(() => {
    router.replace('/503', router.asPath)
  }, [router])

  return <ClipLoader cssOverride={{ display: 'block' }} className='m-auto' />
}

const ServiceUnavailablePage: NextPage = () => {
  return (
    <>
      <Head key='pageHead'>
        <title>Down for maintenance | Trees That Count</title>
        <meta name='robots' content='noindex, nofollow' />
      </Head>
      <FullWidthContainer
        width='base'
        backgroundColor={FullWidthContainerBackgroundColor.white}
      >
        <div className='flex flex-col md:flex-row md:gap-8 md:items-center'>
          <div className='flex-1'>
            <Image
              alt='Trees That Count logo'
              src='/img/brand-name.png'
              height={1063}
              width={827}
              layout='responsive'
            />
          </div>
          <div className='flex-1'>
            <Heading1 className='text-center pb-10'>
              We&apos;ll be back soon!
            </Heading1>
            <Paragraph>
              Sorry for the inconvenience but we&apos;re performing some
              maintenance at the moment. If you need to you can always{' '}
              <ExternalLink href='mailto:hello@treesthatcount.co.nz'>
                contact us
              </ExternalLink>
              , otherwise we&apos;ll be back online shortly!
            </Paragraph>
            <Paragraph>&#8212; The Team at Trees That Count</Paragraph>
          </div>
        </div>
      </FullWidthContainer>
    </>
  )
}

export default ServiceUnavailablePage
