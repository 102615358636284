import ReactModal from 'react-modal'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HRightStack } from 'src/components/templates/HStack'

export interface ConfirmationDialogProps {
  cancelLabel?: string
  confirmLabel?: string
  description?: string
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  title: string
}

export const ConfirmationDialog = ({
  isOpen,
  title,
  description,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  onConfirm,
  onCancel
}: ConfirmationDialogProps) => {
  return (
    <ReactModal
      className='modal-content'
      overlayClassName='modal-overlay'
      isOpen={isOpen}
    >
      <Heading3>{title}</Heading3>
      {description ? <Paragraph>{description}</Paragraph> : null}
      <HRightStack className='mt-8'>
        <Button className='mr-2' onClick={onCancel} variant='outlined'>
          {cancelLabel}
        </Button>
        <Button className='mr-2' onClick={onConfirm} type='submit'>
          {confirmLabel}
        </Button>
      </HRightStack>
    </ReactModal>
  )
}
