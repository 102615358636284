import React, { ReactNode } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { env } from 'src/env/client.mjs'

const siteKey = env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY

export const RecaptchaProvider = ({ children }: { children: ReactNode }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      {children}
    </GoogleReCaptchaProvider>
  )
}
