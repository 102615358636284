import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { IconType } from 'react-icons'
import { MdClose } from 'react-icons/md'

import { IconButton } from 'src/components/atoms/IconButton'
import { Paragraph } from 'src/components/atoms/Typography'

import { Notification } from './Notification'

export enum NotificationType {
  success = 'success',
  info = 'info',
  error = 'error',
  warning = 'warning'
}

export function notify({
  id,
  message,
  type,
  icon,
  duration = 3000
}: {
  id?: string
  message: string
  type?: NotificationType
  duration?: number
  icon?: IconType
}) {
  const IconElement = icon

  return toast.custom(
    t => {
      return (
        <Notification
          className={t.visible ? 'animate-enter' : 'animate-leave'}
          type={type}
        >
          <div className='flex gap-2 align-middle'>
            {IconElement ? <IconElement fontSize={20} /> : null}
            <Paragraph className='!mb-0 text-[16px] leading-[24px] font-medium'>
              {message}
            </Paragraph>
          </div>
          <IconButton
            icon={MdClose}
            size='small'
            ariaLabel={`Dismiss toast ${t.id}`}
            className='!flex-none text-white !p-0'
            onClick={() => toast.remove(t.id)}
          />
        </Notification>
      )
    },
    { id, duration }
  )
}

export const NotificationProvider = ({
  children
}: {
  children: JSX.Element
}) => {
  return (
    <>
      {children}
      <Toaster position='top-center' />
    </>
  )
}
