interface HStackProps extends React.HTMLProps<HTMLDivElement> {}

export const HStack = ({ className = '', ...props }: HStackProps) => {
  return <div className={`flex flex-row ${className}`} {...props} />
}

export const HCenterStack = ({ className = '', ...props }: HStackProps) => {
  return (
    <HStack className={`justify-center flex-wrap ${className}`} {...props} />
  )
}

export const HRightStack = ({ className = '', ...props }: HStackProps) => {
  return <HStack className={`justify-end ${className}`} {...props} />
}

export const HSpaceBetweenStack = ({
  className = '',
  ...props
}: HStackProps) => {
  return <HStack className={`justify-between ${className}`} {...props} />
}
