import { PropsWithChildren } from 'react'
import { IntercomProvider as IntercomProviderImported } from 'react-use-intercom'

import { env } from 'src/env/client.mjs'

const { NEXT_PUBLIC_INTERCOM_APP_ID } = env

export const IntercomProvider = ({ children }: PropsWithChildren) => {
  return (
    <IntercomProviderImported
      apiBase='https://api-iam.intercom.io'
      appId={NEXT_PUBLIC_INTERCOM_APP_ID}
    >
      {children}
    </IntercomProviderImported>
  )
}
