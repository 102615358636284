import { IconType } from 'react-icons'

export interface IconButtonProps {
  ariaLabel: string
  className?: string
  icon: IconType
  color?: 'primary' | 'secondary' | 'tertiary' | 'primary-inverted'
  disabled?: boolean
  onClick?: () => void
  size?: 'small' | 'medium' | 'large'
}

export const IconButton = ({
  ariaLabel,
  className = '',
  icon,
  color,
  disabled = false,
  onClick,
  size = 'small'
}: IconButtonProps) => {
  const buildButtonColorClasses = () => {
    let colorStyles = ''
    switch (color) {
      case 'primary':
        colorStyles =
          'border-primary bg-transparent text-primary hover:bg-primary/[.04]'
        break
      case 'primary-inverted':
        colorStyles =
          'border-primary bg-primary text-white hover:bg-primary/[.6] hover:border-transparent'
        break
      case 'secondary':
        colorStyles =
          'border-secondary bg-secondary text-ivoryBlack hover:bg-secondaryDark'
        break
      case 'tertiary':
        colorStyles =
          'border-tertiary bg-tertiary text-ivoryBlack hover:bg-tertiaryDark'
        break
      default:
        colorStyles =
          'border-transparent bg-transparent text-black hover:text-primary'
        break
    }
    return colorStyles
  }

  const buildButtonSizeClasses = () => {
    let sizeStyles = ''
    switch (size) {
      case 'small':
        sizeStyles = 'p-2'
        break
      case 'medium':
        sizeStyles = 'p-2'
        break
      case 'large':
        sizeStyles = 'p-2'
        break
      default:
        sizeStyles = ''
        break
    }
    return sizeStyles
  }

  const buildIconSize = () => {
    switch (size) {
      case 'small':
        return 20
      case 'medium':
        return 24
      case 'large':
        return 28
      default:
        return 20
    }
  }

  const buildDisabledClasses = () => {
    let disabledStyles = ''
    if (disabled) {
      disabledStyles =
        '!bg-black/[0.07] !border-transparent !text-[#aaaaaa] pointer-events-none cursor-default shadow-none'
    }
    return disabledStyles
  }

  const IconElement = icon

  return (
    <button
      aria-label={ariaLabel}
      disabled={disabled}
      className={`border inline-flex items-center justify-center relative box-border outline-current outline-0 m-0 cursor-pointer select-none align-middle appearance-none no-underline flex-auto text-3xl rounded-full transition-colors duration-[250ms] ease-in-out shadow-none normal-case ${buildButtonColorClasses()} ${buildButtonSizeClasses()} ${buildDisabledClasses()} ${className}`}
      onClick={onClick}
      title={ariaLabel}
      type='button'
    >
      <IconElement size={buildIconSize()} />
    </button>
  )
}
