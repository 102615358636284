import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'

import {
  ConfirmationDialog,
  ConfirmationDialogProps
} from './ConfirmationDialog'

type ConfirmationContextType = {
  confirm: (
    data: Omit<ConfirmationDialogProps, 'isOpen' | 'onCancel' | 'onConfirm'>
  ) => Promise<boolean>
}

type ConfirmationProviderProps = {
  children: React.ReactNode
}

const ConfirmationContext = createContext<ConfirmationContextType>(
  {} as ConfirmationContextType
)

export const ConfirmationProvider = ({
  children
}: ConfirmationProviderProps) => {
  const [state, setState] = useState<
    Omit<ConfirmationDialogProps, 'onCancel' | 'onConfirm'>
  >({ isOpen: false, title: 'Confirm' })
  const resolver = useRef<Function>()

  const confirm = useCallback(
    (data: any): Promise<boolean> => {
      return new Promise(resolve => {
        setState({ ...data, isOpen: true })
        resolver.current = resolve
      })
    },
    [setState]
  )

  const handleCancel = () => {
    if (resolver.current) {
      resolver.current(false)
      setState({ ...state, isOpen: false })
    }
  }

  const handleConfirm = () => {
    if (resolver.current) {
      resolver.current(true)
      setState({ ...state, isOpen: false })
    }
  }

  const memoisedValue = useMemo(() => ({ confirm }), [confirm])

  return (
    <ConfirmationContext.Provider value={memoisedValue}>
      {children}
      <ConfirmationDialog
        {...state}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </ConfirmationContext.Provider>
  )
}

export const useConfirm = () => useContext(ConfirmationContext)
