import { FallbackProps } from 'react-error-boundary'

import { Button } from 'src/components/atoms/Button'
import { Image } from 'src/components/atoms/Image'
import { Heading1 } from 'src/components/atoms/Typography'

import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from '../Container'

export const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <FullWidthContainer
      width='base'
      backgroundColor={FullWidthContainerBackgroundColor.white}
    >
      <div className='flex flex-col-reverse md:flex-row md:gap-8 md:items-center'>
        <div className='flex-1'>
          <Image
            alt='Trees That Count logo'
            src='/img/brand-name.png'
            height={1063}
            width={827}
          />
        </div>
        <div className='flex-1'>
          <Heading1 className='text-center pb-10'>
            Something went wrong...
          </Heading1>
          <pre>{error.message}</pre>
          <Button onClick={resetErrorBoundary}>Reload page</Button>
        </div>
      </div>
    </FullWidthContainer>
  )
}
