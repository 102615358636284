import { ReactNode } from 'react'

import { NotificationType } from './NotificationProvider'

export interface NotificationProps {
  children?: ReactNode
  className?: string
  type?: NotificationType
}

export const Notification = ({
  children = null,
  className = '',
  type = NotificationType.info
}: NotificationProps) => {
  const buildNotificationTypeClasses = () => {
    switch (type) {
      case NotificationType.success:
        return 'bg-success text-white'
      case NotificationType.info:
        return 'bg-info text-white'
      case NotificationType.error:
        return 'bg-error text-white'
      case NotificationType.warning:
        return 'bg-warning text-white'
      default:
        return 'bg-white text-ivoryBlack'
    }
  }

  return (
    <div
      className={`container flex justify-between items-center p-4 pl-5 rounded-xl transition-all shadow-[0px_3px_5px_-1px_rgba(0,0,0,0.2),0px_6px_10px_0px_rgba(0,0,0,0.14),0px_1px_18px_0px_rgba(0,0,0,0.12)] ${buildNotificationTypeClasses()} ${className}`}
    >
      {children}
    </div>
  )
}
