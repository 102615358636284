export enum Stages {
  OFFLINE = 'offline', // for local development
  TEST = 'test', // for tests
  PREVIEW = 'preview', // for preview builds in Vercel
  DEV = 'dev', // for the 'dev' environment (develop branch)
  STAGING = 'staging', // for the 'staging' environment (staging branch)
  PRODUCTION = 'prod' // for the 'production' environment (main branch)
}

export const DEVELOPMENT_ENVIRONMENTS = [
  Stages.OFFLINE,
  Stages.TEST,
  Stages.PREVIEW
]
