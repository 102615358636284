/* eslint-disable jsx-a11y/alt-text */
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import { useEffect, useState } from 'react'

export const DEFAULT_IMG_SRC = '/img/default.png'

interface ImageProps extends NextImageProps {
  fallbackSrc?: ImageProps['src']
  fillParent?: boolean
}

export const Image = ({
  fallbackSrc = DEFAULT_IMG_SRC,
  src,
  fillParent = false,
  ...props
}: ImageProps) => {
  const [imgSrc, setImgSrc] = useState<ImageProps['src']>(src)
  useEffect(() => setImgSrc(src), [src])

  const renderImage = () => {
    return (
      <NextImage
        {...props}
        src={imgSrc}
        onError={() => setImgSrc(fallbackSrc)}
      />
    )
  }

  return fillParent ? (
    <div className='fill-parent'>{renderImage()}</div>
  ) : (
    renderImage()
  )
}
