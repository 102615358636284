import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { useAuth0 } from '@auth0/auth0-react'

import { env } from 'src/env/client.mjs'

export const AuthorizedApolloProvider = ({ children }: { children: any }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const authLink = setContext(async (_, { headers }) => {
    const requestHeaders = { ...headers }
    if (isAuthenticated) {
      const token = await getAccessTokenSilently()
      requestHeaders.Authorization = `Bearer ${token}`
    }
    return {
      headers: requestHeaders
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        )
      })
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const httpLink = createHttpLink({
    uri: env.NEXT_PUBLIC_HASURA_ENDPOINT
  })

  const link = authLink.concat(errorLink).concat(httpLink)

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
