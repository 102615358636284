// @ts-check
import { z } from 'zod'

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  ADMIN_CONTACT_EMAIL_ADDRESS: z.string().email(),
  ADMIN_BUSINESS_CONTACT_EMAIL_ADDRESS: z.string().email(),
  CAMPAIGN_MONITOR_API_KEY: z.string(),
  HASURA_ADMIN_SECRET: z.string(),
  EVENT_SECRET_KEY: z.string(),
  SLACK_TOKEN: z.string(),
  STAGE: z.string(),
  SLACK_APPLICATION_CHANNEL: z.string(),
  SLACK_ERRORS_CHANNEL: z.string(),
  SLACK_PLANTING_CHANNEL: z.string(),
  SLACK_PURCHASE_CHANNEL: z.string(),
  RECAPTCHA_SECRET: z.string(),
  INTERCOM_SECRET_KEY: z.string(),
  STRIPE_API_KEY: z.string(),
  STRIPE_PRICE_ID: z.string(),
  STRIPE_ENDPOINT_SECRET: z.string()
})

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
  NEXT_PUBLIC_APP_DOMAIN: z.string(),
  NEXT_PUBLIC_ASSETS_DOMAIN: z.string().url(),
  NEXT_PUBLIC_AUTH0_CLIENT_ID: z.string(),
  NEXT_PUBLIC_AUTH0_DOMAIN: z.string(),
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
  NEXT_PUBLIC_GOOGLE_MAPS_ENDPOINT: z.string(),
  NEXT_PUBLIC_HASURA_ENDPOINT: z.string(),
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string(),
  NEXT_PUBLIC_SANITY_PROJECT_ID: z.string(),
  NEXT_PUBLIC_SANITY_DATASET: z.string(),
  NEXT_PUBLIC_APP_STAGE: z.string(),
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: z.string(),
  NEXT_PUBLIC_GTM_IDENTIFIER: z.string(),
  NEXT_PUBLIC_GTM_AUTH_ID: z.string(),
  NEXT_PUBLIC_GTM_PREVIEW_ENV: z.string(),
  NEXT_PUBLIC_MAINTENANCE_MODE: z.string(),
  NEXT_PUBLIC_MAINTENANCE_MODE_ALLOWED_IPS: z.string(),
  NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
  NEXT_PUBLIC_INTERCOM_ENABLED: z.string(),
  NEXT_PUBLIC_HELPCENTRE_URL: z.string()
})

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
  NEXT_PUBLIC_ASSETS_DOMAIN: process.env.NEXT_PUBLIC_ASSETS_DOMAIN,
  NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXT_PUBLIC_HASURA_ENDPOINT: process.env.NEXT_PUBLIC_HASURA_ENDPOINT,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  NEXT_PUBLIC_GOOGLE_MAPS_ENDPOINT:
    process.env.NEXT_PUBLIC_GOOGLE_MAPS_ENDPOINT,
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
  NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  NEXT_PUBLIC_APP_STAGE: process.env.NEXT_PUBLIC_APP_STAGE,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_GTM_IDENTIFIER: process.env.NEXT_PUBLIC_GTM_IDENTIFIER,
  NEXT_PUBLIC_GTM_AUTH_ID: process.env.NEXT_PUBLIC_GTM_AUTH_ID,
  NEXT_PUBLIC_GTM_PREVIEW_ENV: process.env.NEXT_PUBLIC_GTM_PREVIEW_ENV,
  NEXT_PUBLIC_MAINTENANCE_MODE: process.env.NEXT_PUBLIC_MAINTENANCE_MODE,
  NEXT_PUBLIC_MAINTENANCE_MODE_ALLOWED_IPS:
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE_ALLOWED_IPS,
  NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  NEXT_PUBLIC_INTERCOM_ENABLED: process.env.NEXT_PUBLIC_INTERCOM_ENABLED,
  NEXT_PUBLIC_HELPCENTRE_URL: process.env.NEXT_PUBLIC_HELPCENTRE_URL
}
