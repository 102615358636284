import { getCookie, setCookie } from 'cookies-next'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo
} from 'react'

declare global {
  interface Window {
    gtag: (key: string, action: string, args: { [key: string]: string }) => void
  }
}

type ConsentContextProps = {
  allowCookies: () => void
  denyCookies: () => void
  hasConsent: boolean
}

const COOKIE_NAME = `localConsent`

const ConsentContext = createContext<ConsentContextProps>(
  {} as ConsentContextProps
)

export interface ConsentProviderProps {
  children: ReactNode
}

export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const hasConsent = Boolean(getCookie(COOKIE_NAME)) || false

  const setConsentCookie = (value: boolean) => {
    setCookie(COOKIE_NAME, value, { maxAge: 60 * 60 * 24 * 365 })
  }

  const updateGTMConsent = (isAllowed: boolean) => {
    setConsentCookie(isAllowed)
    if (window && window?.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: isAllowed ? 'granted' : 'denied',
        analytics_storage: isAllowed ? 'granted' : 'denied'
      })
      // Manually triggers a `consent_granted` event to fire the Facebook/TikTok pixels events in GTM
      if (isAllowed) window.gtag('event', 'consent_granted', {})
    }
  }

  const allowCookies = useCallback(() => {
    updateGTMConsent(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const denyCookies = useCallback(() => {
    updateGTMConsent(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // for testing, I commented this out:
  // I believe this is what's causing the
  // reported by MBM double event tracking

  // useEffect(() => {
  //   if (hasCookie(COOKIE_NAME)) {
  //     if (getCookie(COOKIE_NAME)) {
  //       allowCookies()
  //     } else {
  //       denyCookies()
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const memoisedValue = useMemo(() => {
    return {
      allowCookies,
      denyCookies,
      hasConsent
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCookies, denyCookies, hasConsent])

  return (
    <ConsentContext.Provider value={memoisedValue}>
      {children}
    </ConsentContext.Provider>
  )
}

export const useConsent = () => useContext(ConsentContext)
