import { env } from 'src/env/client.mjs'

export const isAllowedIp = (ipAddress?: string) => {
  if (!ipAddress) return true

  const allowedIps = JSON.parse(
    env.NEXT_PUBLIC_MAINTENANCE_MODE_ALLOWED_IPS
  ) as string[]

  return allowedIps.includes(ipAddress)
}
