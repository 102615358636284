import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { IoCloseCircle } from 'react-icons/io5'
import { useSwipeable } from 'react-swipeable'

import { DEFAULT_IMG_SRC, Image } from 'src/components/atoms/Image'
import { OnClickLink } from 'src/components/atoms/Link'

export type ImagePreviewProps = {}

export type ImagePreviewContextProps = {
  images: Array<string>
  setImages: (images: Array<string>) => void
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  isVisible: boolean
  close: () => void
  show: (url?: string) => void
}

export const ImagePreviewContext = createContext<ImagePreviewContextProps>({
  images: [],
  setImages: () => {},
  selectedIndex: 0,
  setSelectedIndex: () => {},
  isVisible: false,
  close: () => {},
  show: () => {}
})

export const ImagePreviewProvider = ({ children }: PropsWithChildren) => {
  const [imageUrls, setImageUrls] = useState<Array<string>>([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  const value = useMemo<ImagePreviewContextProps>(
    () => ({
      images: imageUrls,
      setImages: setImageUrls,
      selectedIndex,
      setSelectedIndex,
      isVisible,
      close: () => {
        setSelectedIndex(0)
        setIsVisible(false)
      },
      show: (url?: string) => {
        const index = url ? imageUrls.findIndex(u => u === url) : 0

        setSelectedIndex(index === -1 ? 0 : index)
        setIsVisible(true)
      }
    }),
    [imageUrls, isVisible, selectedIndex]
  )

  return (
    <ImagePreviewContext.Provider value={value}>
      {children}
    </ImagePreviewContext.Provider>
  )
}

export const useImagePreview = () => {
  const context = useContext(ImagePreviewContext)

  const setImageUrls = (urls: Array<string>) => {
    context.setImages(urls)
  }

  const next = useCallback(() => {
    if (context.selectedIndex < context.images.length - 1) {
      context.setSelectedIndex(context.selectedIndex + 1)
    }
  }, [context])

  const previous = useCallback(() => {
    if (context.selectedIndex > 0) {
      context.setSelectedIndex(context.selectedIndex - 1)
    }
  }, [context])

  return {
    images: context.images,
    setImageUrls,
    selectedIndex: context.selectedIndex,
    next,
    previous,
    isVisible: context.isVisible,
    close: context.close,
    show: context.show
  }
}

export const ImagePreview = () => {
  const { images, selectedIndex, next, previous, isVisible, close } =
    useImagePreview()

  const handlers = useSwipeable({
    onSwipedDown: close,
    onSwipedLeft: next,
    onSwipedRight: previous,
    delta: 10,
    trackTouch: true
  })

  const handleKeyPress = useCallback(
    (ev: KeyboardEvent) => {
      switch (ev.key) {
        case 'Escape':
          close()
          break
        case 'ArrowLeft':
          previous()
          break
        case 'ArrowRight':
          next()
          break
        default:
          // do nothing
          break
      }
    },
    [close, next, previous]
  )

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleKeyPress)

      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }
  }, [isVisible, handleKeyPress])

  const selectedImageSrc = useMemo(() => {
    return images[selectedIndex] || DEFAULT_IMG_SRC
  }, [images, selectedIndex])

  const NavButton = useCallback(
    ({
      direction,
      className = ''
    }: {
      direction: 'next' | 'previous'
      className?: string
    }) => {
      const disabled =
        (selectedIndex <= 0 && direction === 'previous') ||
        (selectedIndex >= images.length - 1 && direction === 'next')

      const Icon = direction === 'next' ? FaChevronRight : FaChevronLeft

      const onClick = () => {
        if (disabled) return
        if (direction === 'next') {
          next()
        }
        if (direction === 'previous') {
          previous()
        }
      }

      return (
        <OnClickLink
          onClick={onClick}
          className={`rounded-full ${
            disabled
              ? 'bg-[#3c3c3c] border border-transparent !text-[#aaaaaa] !cursor-default'
              : 'bg-transparent border border-white !text-white hover:text-white'
          } p-4 ${className}`}
        >
          <Icon className='w-2 h-2 md:w-5 md:h-5' />
        </OnClickLink>
      )
    },
    [selectedIndex, images, next, previous]
  )

  if (!isVisible) return null

  return (
    <div
      {...handlers}
      className={`
        select-none 
        fixed 
        flex flex-col gap-[10px] md:gap-5 justify-center items-center 
        z-50 
        left-0 top-0 right-0 bottom-0 
        bg-opacity-95 bg-black 
        px-5
        pt-10 pb-5
        md:px-10 
        lg:pt-32 lg:pb-32
        lg:px-[110px]`}
    >
      <div className='flex flex-row items-center gap-2 lg:gap-16 w-full h-full'>
        <NavButton direction='previous' className='hidden md:block' />
        <div
          key={selectedImageSrc}
          className='bg-no-repeat flex-1 relative w-full h-full animate-[fade_0.3s_linear] md:min-h-[300px]'
        >
          <Image
            alt={`Preview_${selectedIndex}`}
            src={selectedImageSrc}
            fill
            className='object-contain'
          />
        </div>
        <NavButton direction='next' className='hidden md:block' />
      </div>
      <div className='flex flex-row gap-[10px] items-center'>
        <NavButton direction='previous' className='md:hidden block' />
        <span className='text-white flex-1 w-40 text-center'>
          {selectedIndex + 1} of {images.length}
        </span>
        <NavButton direction='next' className='md:hidden block' />
      </div>
      <OnClickLink
        onClick={close}
        className='text-[16px] text-white absolute left-2 top-2 p-[10px] align-middle'
      >
        <IoCloseCircle className='w-4 h-4 mr-1 mb-1 inline-block' /> Close
      </OnClickLink>
    </div>
  )
}
