export enum FullWidthContainerBackgroundColor {
  aquaSpring = 'aquaSpring',
  cyan = 'cyan',
  linen = 'linen',
  olivine = 'olivine',
  white = 'white',
  alabaster = 'alabaster',
  mostlyGreen = 'mostlyGreen',
  transparent = 'transparent'
}

export interface FullWidthContainerProps {
  backgroundColor?: FullWidthContainerBackgroundColor
  children: React.ReactNode
  width?: 'narrow' | 'base' | 'wide' | 'fixed-narrow'
  verticalPadding?: 'base' | 'short' | 'none'
  id?: string
  className?: string
  skipContainer?: boolean
}

export const FullWidthContainer = ({
  backgroundColor,
  verticalPadding = 'base',
  children,
  width = 'base',
  className = '',
  id,
  skipContainer = false
}: FullWidthContainerProps) => {
  const buildBackgroundColorClasses = () => {
    switch (backgroundColor) {
      case FullWidthContainerBackgroundColor.aquaSpring:
        return 'bg-aquaSpring'
      case FullWidthContainerBackgroundColor.cyan:
        return 'bg-cyan'
      case FullWidthContainerBackgroundColor.linen:
        return 'bg-linen'
      case FullWidthContainerBackgroundColor.olivine:
        return 'bg-olivine'
      case FullWidthContainerBackgroundColor.white:
        return 'bg-white'
      case FullWidthContainerBackgroundColor.alabaster:
        return 'bg-alabaster'
      case FullWidthContainerBackgroundColor.mostlyGreen:
        return 'bg-mostlyGreen'
      case FullWidthContainerBackgroundColor.transparent:
        return 'bg-transparent'
      default:
        return 'bg-white'
    }
  }

  const buildVerticalPaddingClasses = () => {
    switch (verticalPadding) {
      case 'base':
        return 'py-[30px] md:py-12'
      case 'short':
        return 'py-[30px]'
      default:
        return 'py-0'
    }
  }

  const buildContainerWidth = () => {
    switch (width) {
      case 'fixed-narrow':
        return 'fixed-narrow-container'
      case 'narrow':
        return 'narrow-container'
      case 'wide':
        return 'wide-container'
      default:
        return 'container'
    }
  }

  const buildHorizontalPaddingClasses = () => {
    switch (width) {
      case 'fixed-narrow':
        return ''
      default:
        return 'px-4 md:px-9'
    }
  }

  if (skipContainer) {
    return (
      <div id={id} className={className}>
        {children}
      </div>
    )
  }

  return (
    <div id={id} className={buildBackgroundColorClasses()}>
      <div className={`${buildContainerWidth()} mx-auto`}>
        <div
          className={`${buildHorizontalPaddingClasses()} ${buildVerticalPaddingClasses()} relative ${className}`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
