import { useMemo } from 'react'

type ColorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'black'
  | 'black-underlined'
  | 'none'

interface ExternalLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  color?: ColorType
}

export const ExternalLink = ({
  className = '',
  children,
  color = 'primary',
  href,
  ...props
}: ExternalLinkProps) => {
  const buildLinkColorClasses = () => {
    switch (color) {
      case 'primary':
        return 'text-primary hover:text-primaryDark'
      case 'secondary':
        return 'text-secondary hover:text-secondaryDark'
      case 'tertiary':
        return 'text-tertiary hover:text-tertiaryDark'
      case 'black':
        return 'text-shark hover:text-primary'
      case 'black-underlined':
        return 'font-normal text-shark underline hover:no-underline'
      default:
        return ''
    }
  }

  const url = useMemo(() => {
    if (!href) return ''
    if (/^(http|https):\/\//.test(href)) return href
    return `https://${href}`
  }, [href])

  return (
    <a
      target='_blank'
      className={`cursor-pointer font-medium transition-colors duration-150 ease-in-out ${buildLinkColorClasses()} ${className}`}
      href={url}
      {...props}
    >
      {children}
    </a>
  )
}
