import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo
} from 'react'

export enum SupportedSortParams {
  Column = 'sortColumn',
  SortOrder = 'sortOrder'
}

export type SortDirection = 'asc' | 'desc'

export type SortParamValues = {
  sortColumn: string
  sortOrder: SortDirection
}

export interface SortContextProps {
  sort: SortParamValues
  setSort: (newSort: Partial<SortParamValues>) => void
  clearSort: () => void
}

export const SortContext = createContext<SortContextProps>(
  {} as SortContextProps
)

export const SortProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const router = useRouter()

  const sort = useMemo(() => {
    return Object.values(SupportedSortParams).reduce<SortParamValues>(
      (obj: SortParamValues, sortKey: SupportedSortParams) => {
        if (router.query[sortKey])
          return {
            ...obj,
            [sortKey]: router.query[sortKey]
          }
        return {} as SortParamValues
      },
      {} as SortParamValues
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const applyAllowlistAndStripNullEntries = (
    values: Partial<SortParamValues>
  ) => {
    return Object.entries(values).reduce<SortParamValues>(
      (obj: SortParamValues, [key, val]) => {
        if (
          Object.values(SupportedSortParams).includes(
            key as SupportedSortParams
          ) &&
          val !== null
        )
          return { ...obj, [key]: val }
        return obj
      },
      {} as SortParamValues
    )
  }

  const setSort = useCallback(
    (newSort: Partial<SortParamValues>) => {
      const params = applyAllowlistAndStripNullEntries(newSort)

      Object.entries(params).forEach(
        ([paramKey, paramVal]: [string, string]) => {
          if (paramVal) router.query[paramKey] = paramVal
        }
      )

      router.push(
        { pathname: router.pathname, query: router.query },
        undefined,
        {
          scroll: false,
          shallow: true
        }
      )
    },
    [router]
  )

  const clearSort = useCallback(() => {
    Object.values(SupportedSortParams).forEach(
      (filter: SupportedSortParams) => {
        delete router.query[filter]
      }
    )

    router.push({ pathname: router.pathname, query: router.query }, undefined, {
      scroll: false,
      shallow: true
    })
  }, [router])

  const memoisedValue = useMemo<SortContextProps>(
    () => ({
      clearSort,
      setSort,
      sort
    }),
    [clearSort, setSort, sort]
  )

  return (
    <SortContext.Provider value={memoisedValue}>
      {children}
    </SortContext.Provider>
  )
}

export const useSort = () => useContext(SortContext)
